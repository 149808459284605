.Toastify__toast-icon {
  margin-right: 9px;
}
.Toastify__toast-body{
  padding: 6px 0px 6px 3px;
}

.Toastify__toast--success::before {
  content: ' ';
  width: 5px;
  height: 100% - 10%;
  top: 60px;
  left: 961px;
  border-radius: 10px;
  margin-right: 6px;
  margin-bottom: 2px;
  background-color: #6fbe44;
}

.Toastify__toast--info::before {
  content: ' ';
  width: 16px;
  height: 100% - 10%;
  top: 60px;
  left: 961px;
  border-radius: 10px;
  margin-right: 6px;
  margin-bottom: 2px;
  background-color: #3498db;
}

.Toastify__toast--warning::before {
  content: ' ';
  width: 5px;
  height: 100% - 10%;
  top: 60px;
  left: 961px;
  border-radius: 10px;
  margin-right: 6px;
  margin-bottom: 2px;
  background-color: #f1c40f;
}

.Toastify__toast--error::before {
  content: ' ';
  width: 5px;
  height: 100% - 10%;
  top: 60px;
  left: 961px;
  border-radius: 10px;
  margin-right: 6px;
  margin-bottom: 2px;
  background-color: #e74c3c;
}

.toastContainer > div:nth-child(1) {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  color: #242424;
  line-height: 26px;
  /* margin-bottom: 0.1rem; */
}
.toastContainer > div:nth-child(2) {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #838485;
}
