.ql-mention-list-container {
  z-index: 9;
}

.ql-tooltip {
  position: unset !important;
}

.ql-action {
  min-width: 15%;
}
.ql-action::before {
  display: flex !important;
  justify-content: center;
}

.ql-editor {
  min-height: 200px;
  font-size: 16px;
}

.ql-clear-button {
  border: 1px solid #DEE2E6;
  background-color: #FFFFFF;
}

.ql-clear-button:active {
  background-color: #efe6ff;
  color: #7039ED;
}
