.main {
   height: 36px;
   padding: 0 20px;
}

.primary {
   background-color: #7039ED;
   color: #FFF;
}

.primary:hover {
   background-color: #522BB5;
   color: #FFFFFF;
   border: 1px solid #522BB5;
}

.primary:active {
   background-color: #351B77;
   color: #FFFFFF;
   border: 1px solid #351B77;
}

.primary_disable {
   background-color: rgba(112, 57, 237, 0.5);
   color: #FFF;
   cursor: not-allowed;
}

.primary_disable:hover {
   background-color: rgba(112, 57, 237, 0.5);
   color: #FFF;
}

.secondary {
   background: none;
   color: #7039ED;
}

.secondary:hover {
   background: rgba(82, 43, 181, 0.1);
   border: 1px solid #522BB5;
   color: #522BB5;
}

.secondary:active {
   background: rgba(53, 27, 119, 0.1);
   border: 1px solid #351B77;
   box-shadow: 0px 1px 4px rgba(131, 132, 133, 0.2), inset 0px 2px 4px rgba(131, 132, 133, 0.25);
}

.secondary:disabled {
   background: none !important;
   color: rgba(112, 57, 237, 0.5) !important;
}

.secondary_disable {
   background: none;
   color: rgba(112, 57, 237, 0.5);
   cursor: not-allowed;
}

.secondary_disable:hover {
   background: none;
   color: rgba(112, 57, 237, 0.5);
   border: none;
}

.neutral {
   background-color: #FFF;
   color: var(--gray-web-color);
   border: 1px solid #838485;
}

.neutral:hover {
   background-color: #EEEEEE;
   color: #4F4F4F;
   border: 1px solid #4F4F4F;
}

.neutral:active {
   background-color: #DFE1E1;
   color: #4F4F4F;
   border: 1px solid #4F4F4F;
}

.link {
   color: #249FC8;
   background: none;
   padding: 0;
   height: auto;
}

.link:hover {
   text-decoration-line: underline;
   background: none;
}

.link:active {
   text-decoration-line: underline;
   color: #147CA2;
   background: none;
}

.destructive {
   background: #E50B14;
}

.destructive:hover {
   background: #CF242B;
}

.destructive:active {
   background: #B90008;
}

.stand {
   background: none;
   color: #E50B14;
}

.stand:hover {
   background: rgba(207, 36, 43, 0.1);
   border: 1px solid #CF242B;
   color: #CF242B;
   box-shadow: 0px 1px 4px rgba(131, 132, 133, 0.2);
}

.stand:active {
   background: rgba(185, 0, 8, 0.1);
   border: 1px solid #B90008;
   box-shadow: 0px 1px 4px rgba(131, 132, 133, 0.2), inset 0px 2px 4px rgba(131, 132, 133, 0.25);
   color: #B90008;
}