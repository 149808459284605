.recharts-legend-wrapper {
    margin-right: 65px;
}

.recharts-default-legend {
    display: flex;
    flex-direction: column;
    height: 220px;
    flex-wrap: wrap;
}

.recharts-legend-item {
    /* Forgive me for the !important flag, this one is
    used to modify a 3rd party library styling */
    margin-right: 5rem !important;
}